@import "../../styles/scss/index.scss";

.accordion {
    border: 1px solid #ddd;
    border-radius: 4px;
    margin: 10px 0;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
  
    &.open {
      .accordion-header {
        background-color: #f1f1f1;
        border: none;
      }
    }
  
    .accordion-header {
      background-color: #fafafa;
      color: $onyx;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      padding: 15px 0;
      width: 100%;
      border-radius: 1px;
      transition: background-color 0.3s ease-in-out;
      border: none;
  
      h2 {
        margin: 0;
        font-size: 16px;
      }
  
      .accordion-toggle-open {
        font-size: 16px;
        color: $orangerough;
      }
      .accordion-toggle-closed {
        font-size: 16px;
        color: $onyx;
      }
    }
  
    .accordion-content {
      padding: 10px;
      background-color: #fff;
      border-top: 1px solid #ddd;
    }
  }
  