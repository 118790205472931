@import "../../../styles/scss/index.scss";

.login-bg {
  background-image: url("../../../../public/images/login-sigup.jpg") !important;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  // background-position: center;
  background-size: cover;
  min-height: 110vh;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}
.login-card-layout {
  margin: 0;
  display: grid;
  margin-top: 3rem;
  // position:absolute;
  // top: 80% !important;
  // left: 50% !important;
  // transform: translate(-50%, -30%) !important;
}

.login-header {
  color: $grey;
  font-size: 20px;
  font-weight: 500;
}
.login-tagline-text {
  color: $grey;
  font-size: 18px;
  font-weight: 500;
}
.signup-discom-tabs {
  background-color: transparent !important;
  margin: 0 8px !important;
  padding: 4px 3px !important;
  border: none;
  &:hover {
    background: $whitesmoke !important;
    box-shadow: 1px 6px 8px rgb(0 55 183 / 8%);
  }
}
.signup-discom-tabs.active {
  background-color: $whitesmoke !important;
  margin: 0 8px !important;
  padding: 4px 3px !important;
  border: none;
  border-bottom: 1px solid $elctraliteblue !important;
  &:hover {
    background-color: $milkwhite !important;
  }
  //   background: $whitesmoke !important;
  //   letter-spacing: 1px;
  //   border: none;
  //   // padding: 5px !important;
  //   margin: 0 !important;
  //   padding: 0 !important;
  // border-bottom: 1px solid $elctraliteblue !important;
  //   &:hover {
  //     padding: 5px !important;
  //   }
}
.discom-signup-bg {
  background-color: $whitesmoke !important;
  margin: 1px 1px !important;
  padding: 5px 0;
  border-radius: 2px;
}
.discom-signup-buz {
  background-color: $milkwhite !important;
  margin: 1px 1px !important;
  padding: 5px 0;
  border: 1px solid $whisper;
  border-radius: 4px;
}

.discom-liting-text {
  font-size: 14px;
  font-weight: 400;
  color: $onyx;
}

.text-signup-discom-header {
  color: $onyx;
  font-weight: 500;
  font-size: 12px;
}
.text-signup-discom-icon {
  height: 40px;
  width: 40px;
  margin: 2px 8px;
  border-radius: 50%;
}
.margin-login {
  padding-top: 10px;
}
.margin-support {
  padding-top: 6rem;
}
.margin-login-card {
  margin-top: 8rem;
}
.min-login-height {
  min-height: 90vh;
}
@media screen and (max-width: 576px) {
  .margin-login-column {
    margin-top: 15px;
  }
  .signup-discom-tabs {
    margin: 0 4px !important;
    padding: 4px 2px !important;
  }
  .signup-discom-tabs.active {
    margin: 0 8px !important;
    padding: 4px 2px !important;
  }
  .min-login-height {
    min-height: 70vh;
  }
  .margin-login-card {
    margin-top: 4rem;
  }
}
