@import "../../../styles/scss/index.scss";

.seller-row-profile-tab-mobile {
    display: none;
  }
  .seller-image-dim{
    width: 100% !important;
    height: 100px !important;
  }
  .seller-row-profile-tab {
    display: block;
  }
  
  .seller-column-profile-tab-left {
    float: left;
  }
  .seller-column-profile-tab-right {
    float: right;
  }
  .seller-profile-nav-tabs {
    color: $elctradarkblue !important;
    background: $whitesmoke !important;
    letter-spacing: 1px;
    font-size: 14px;
    font-weight: 600;
    margin: 1px 10px !important;
    padding: 5px 15px;
  }
  
  .seller-profile-nav-tabs.active {
    color: $elctraliteblue !important;
    background-color: $coldsteel !important;
    letter-spacing: 1px;
    border: none;
    border-bottom: 2px solid $elctraliteblue !important;
  }
  .cat-check-text {
    color: $matrix !important;
    font-size: 15px;
    font-weight: 400;
    font-style: italic;
  }
  .categories-text {
    color: $onyx !important;
    font-size: 15px;
    font-weight: 500;
  }
  .border-up-listing {
    border-top: 1px solid $aliceblue;
    background-color: transparent !important;
  }
  .input-search-button {
    border: 2px solid $aliceblue !important;
    background: $milkwhite !important;
    border-radius: 5px !important;
    margin: 0;
    padding: 8px 10px !important;
    &:focus {
      outline: 0;
      box-shadow: none !important;
    }
    &:hover {
      background-color: $white !important;
      border: 1px solid $matrix !important;
    }
  }
  .seller-product-preview {
    margin-right: 30px;
    margin-bottom: 10px;
    // width: 180px;
    max-height: 100px !important;
    padding: 10px;
    border: 1px solid $milkwhite;
  }
  .dash-card-header {
    padding: 2px !important;
    border: 6px solid $chromeblack; 
    border-radius: 4px;
  }
  .min-text-card-padding{
    min-height: 50px !important;
  }
  @media screen and (max-width: 576px) {
    .seller-row-profile-tab-mobile {
      display: block;
      // margin-top: 10px;
    }
  
    .seller-row-profile-tab {
      display: none;
    }
  
    .seller-tab-profile-icon {
      font-size: 20px;
      margin-right: 10px;
    }
    .seller-profile-tab {
      border: none !important;
    }
    .seller-profile-nav-tabs {
      letter-spacing: 1px;
      font-size: 14px;
      font-weight: 600;
      margin: 5px 10px !important;
      padding: 10px 18px;
      border: none !important;
    }
    .seller-profile-nav-tabs.active {
      color: $elctradarkblue !important;
      background: $whitesmoke !important;
      letter-spacing: 1px;
      border: none;
      border-bottom: 2px solid $elctraliteblue !important;
    }
  }
  