@import "../../../../styles/scss/index.scss";

.my-order-label {
  color: $onyx !important;
  background-color: $whisper !important;
  font-size: 16px;
  font-weight: 500;
  margin: 5px 4px !important;
  padding: 5px 25px;
  border-radius: 2px;
}

.my-order-status {
  color: $dodgerblue !important;
  background-color: $white !important;
  font-size: 15px;
  font-weight: 600;
  margin: 0 4px !important;
  padding: 3px 5px;
  border-radius: 2px;
}

.my-order-items-bg {
  background-color: $milkwhite !important;
  margin: 1px 1px !important;
  padding: 3px 5px;
  border-radius: 8px;
}

.order-det-text {
  color: $onyx;
  font-size: 18px !important;
  font-weight: 500 !important;
}

.order-prod-image {
  width: 80px;
  height: 80px;
  padding: 4px;
  margin: 1px;
}
.vert-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.stepper-parent-container {
  width: 100% !important;
  display: flex;
  justify-content: center;
}
.order-cost-text {
  color: $onyx !important;
  font-size: 16px;
  font-weight: 500;
}
.order-margin-right{
  margin-right: 2rem;
}

.power-my-order-accord {
  margin-top: 5px;
  outline: 0;
  background: $white;
  padding: 1px;
  border: 1px solid $whisper;
  box-shadow: 2px 5px 10px rgba(21, 22, 23, 0.1);
  border-radius: 8px;
}
.order-link-size{
  font-size: 14px;
}
.buyer-ship-image{
  height: 50px;
  width: 50px;
}
.power-my-order-accord button {
  border-radius: 0px !important;
  box-shadow: none !important;
  background-color: $white;
  transition: transform 0.5s;
  color: $onyx !important;
  font-size: 16px;
  font-weight: 500;
  border: none !important;

  &:hover {
    background-color: $whitesmoke;
  }

  &:not(.collapsed) {
    background-color: $milkwhite;
    color: $onyx !important;
    font-size: 16px;
    font-weight: 500;
  }
}

// .order-det-text-cont {
//   background-color: $orangerough !important;
//   width: 100%;
// }

@media screen and (max-width: 576px) {
  .order-menu-icon {
    font-size: 28px;
  }
  .order-margin-right{
    margin-right: 2px;
  }
  .order-margin-top{
    margin-top: 20px;
  }
  .auto-order-scroll {
    // display: flex;
    overflow-x: auto; 
    // width: 100%;
  }
}
