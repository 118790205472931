@import "../../styles/scss/index.scss";

.popover-wrapper {
    position: relative;
    display: inline-block;
  }
  
  .popover {
    position: absolute;
    background-color: #fff;
    color: #333;
    text-align: left;
    border-radius: 4px;
    padding: 5px 10px;
    z-index: 1;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    white-space: normal; /* Allow text to wrap */
    word-wrap: break-word;
    width: auto; /* Let the width adjust based on content */
    min-width: 280px;
    &.popover-top {
      bottom: 100%;
      left: 50%;
      transform: translateX(-50%);
      margin-bottom: 10px;
  
      &::after {
        content: '';
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        border-width: 10px;
        border-style: solid;
        border-color: #fff transparent transparent transparent; /* Custom arrow color for top */
      }
    }
  
    &.popover-right {
      top: 50%;
      left: 100%;
      transform: translateY(-50%);
      margin-left: 10px;
  
      &::after {
        content: '';
        position: absolute;
        top: 50%;
        right: 100%;
        transform: translateY(-50%);
        border-width: 10px;
        border-style: solid;
        border-color: transparent transparent transparent #fff; /* Custom arrow color for right */
      }
    }
  
    &.popover-bottom {
      top: 100%;
      left: 50%;
      transform: translateX(-75%);
      margin-top: 10px;
  
      &::after {
        content: '';
        position: absolute;
        bottom: 100%;
        left: 80%;
        transform: translateX(-90%);
        border-width: 10px;
        border-style: solid;
        border-color: transparent transparent #fff transparent; /* Custom arrow color for bottom */
      }
    }
  
    &.popover-left {
      top: 50%;
      right: 100%;
      transform: translateY(-50%);
      margin-right: 10px;
  
      &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 100%;
        transform: translateY(-50%);
        border-width: 10px;
        border-style: solid;
        border-color: transparent #fff transparent transparent; /* Custom arrow color for left */
      }
    }
  }
  
  .popover-header {
    font-weight: 500;
    margin-bottom: 2px;
    background-color: transparent;
  }
  
  .popover-content {
    font-size: 14px;
    white-space: normal; /* Allow text wrapping */
  }
  
  .align-center {
    text-align: center;
  }
  
  .text-lite-grey {
    color: #888;
  }
  
  .button-icon {
    background: none;
    border: none;
    cursor: pointer;
  }
  
  .custom-popover {
    max-width: none; /* Remove maximum width if not needed */
  }
  