@import "./../../styles/scss/index.scss";

.scroller {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.scroller-window {
  display: block;
  width: 100%;
  overflow: hidden;
}

.scroller-slides {
  display: flex;
  transition: transform 0.5s ease;
}

.scroller-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 100%;
  box-sizing: border-box;
}

.scroller-slide img {
  width: 50%;
  height: 65vh;
  padding: 4rem 10px;
  margin-top: -30px;
}

// .scroller-slide-img {
//   width: 100px;
//   height: 65vh;
// }

.scroller-text {
  width: 40%;
  padding: 0 15px;
  // box-sizing: border-box;
}

.header-scroll-text {
  color: $orangerough;
  font-size: 20px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  word-wrap: break-word;
}
.main-scroll-text {
  color: $onyx;
  font-size: 16px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  word-wrap: break-word;
}
.sub-scroll-text {
  color: $onyx;
  font-size: 14px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  word-wrap: break-word;
}

.scroller-dots {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.scroller-dot {
  width: 12px !important;
  height: 12px !important;
  border-radius: 50%;
  background: #ccc;
  margin: 0 5px;
  border: none;
  cursor: pointer;
  outline: none;
}

.scroller-dot.active {
  background: $orangerough;
}

@media (max-width: 768px) {
    .scroller-window {
        display: block;
        width: 100%;
        overflow: hidden;
      }
    .scroller-slide {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center; /* Center align text content */
      width: 20% !important;
    }

    .scroller-slide img {
      width: 100%;
      height: 60vh;
      object-fit: cover;
      margin-top: -90px;
    }
    .scroller-text {
      width: 100%;
      padding: 1rem;
      box-sizing: border-box;
    }

  }

