$chromeblack: #323538;
$coldsteel:#252336;
$elctradarkblue: #070748ff;
$elctraliteblue: #05cbddff;
$dodgerblue: #0173ca;
$rust: #C13B06;
$salem: #0A8242;
$thunderbird: #be3c1e;
$cofeebean: #22140C;
$pancho:#E1BF9D;
$whisper: #E6E6E6;
$yellowbright: #FBFF59;
$neonbright: #96FC4D;
// NEW COLOR COMBO
$white: #ffffff;
$milkwhite: #fffbfb;
$whitesmoke: #F6F6F6;
$aliceblue: #e0f3fc;
$silver-lite: #dccece;
$silver: #BFBFBF;
$grey:#807F7F;
$onyx: #383838;
$greenvogue: #11404D;
$matrix: #A4504C;
$orangerough: #D5521C;
$medcarmine: #af4631;
