@import "../../../../styles/scss/index.scss";

.dashboard-sadmin-row-profile-tab-mobile {
  display: none !important;
}

.dashboard-sadmin-row-profile-tab {
  display: block !important;
}

.dashboard-sadmin-column-profile-tab-left {
  float: left;
}
.dashboard-sadmin-column-profile-tab-right {
  float: right;
}
.dashboard-sadmin-profile-nav-tabs {
  color: $elctradarkblue !important;
  background: $whitesmoke !important;
  letter-spacing: 1px;
  font-size: 14px;
  font-weight: 600;
  margin: 1px 10px !important;
  padding: 5px 15px;
}

.dashboard-sadmin-profile-nav-tabs.active {
  color: $elctraliteblue !important;
  background-color: $coldsteel !important;
  letter-spacing: 1px;
  border: none;
  border-bottom: 2px solid $elctraliteblue !important;
}

.super-dashboard-header {
  color: $elctraliteblue !important;
  font-size: 15px;
  font-weight: 600;
}
.dash-seller-chat-icon{
  color: $onyx;
  font-size: 15px;
}
.dash-seller-cert-icon{
  color: $medcarmine;
  font-size: 15px;
}
.dash-seller-chat-text{
  color: $onyx;
  font-size: 15px;
}
.seller-image-icon{
  height: auto;
  width: 60px
}
.shiping-image-modal{
  height: 50px;
  width: 50px
}
.super-dashboard-name {
  color: $white !important;
  font-size: 15px;
  font-weight: 500;
}

.dash-pending-text {
  color: $yellowbright !important;
  font-size: 15px;
  font-weight: 600;
}

.dash-completed-text {
  color: $neonbright !important;
  font-size: 15px;
  font-weight: 600;
}

.order-id-text {
  color: $yellowbright !important;
  font-size: 14px;
  font-weight: 500;
}

.dash-txn-check{
  color: $onyx;
  font-size: 16px;
  font-weight: 600;
}

.dash-hr-white {
  color: $milkwhite !important;
  padding: 5px 1rem;
}

.super-dashboard-sub-name {
  color: $white !important;
  font-size: 14px;
  font-weight: 400;
}
.super-dashboard-on-hold {
  color: $elctraliteblue !important;
  font-size: 15px;
  font-weight: 400;
}
.box-dashboard-type {
  color: $onyx !important;
  font-size: 12px;
  font-weight: 600;
  background-color: $whitesmoke;
  padding: 1px 5px;
  margin: 5px 2px;
}

.dashboard-sadmin-tab-profile-icon {
  font-size: 17px;
  margin-right: 10px;
}
.dashboard-sadmin-card-body-atbtme {
  min-height: 150px !important;
}
.product-preview {
  margin-right: 30px;
  margin-bottom: 10px;
  // width: 180px;
  // height: 180px;
  padding: 10px;
  border: 1px solid $milkwhite;
}
.product-image-btn{
  border: 0 none !important;
}
.product-image-btn-border{
  border: 1px solid $elctraliteblue !important;
}
input[type=file].product-img {
  background: none !important;
  color: $onyx;
  font-size: 13px;
  font-weight: 600;
  &:focus {
    outline: 0 !important;
    box-shadow: none !important;
  }
}
.dash-card{
  background-color: $chromeblack;
  border: 1px solid $whitesmoke;
  box-shadow: 1px 6px 8px rgb(0 55 183 / 8%);
  border-radius: 8px;
  padding: 5px 5px;
}
.dash-card-header{
  background: $milkwhite;
  border: none;
  font-size: 16px;
  font-weight: 400;
  color: $onyx;
}
.dash-product-image {
  width: 100px;
  height: 100px;
  padding: 1px;
}
.seller-product-image {
  width: 100%;
  height: 150px;
  padding: 1px;
}
.border-image{
  border: 1px solid $aliceblue;
  padding: 10px;
  // margin: 3px;
}
.dash-price-main{
  font-size: 28px;
  font-weight: 600;
  color: $onyx;
}
.dash-price-icon{
  font-size: 18px;
  font-weight: 400;
  color: $grey;
  padding: 5px 0;
}
.dash-price-lite{
  font-size: 16px;
  font-weight: 400;
  color: $grey;
}
.dash-waranty-lite{
  font-size: 15px;
  font-weight: 400;
  color: $grey;
  font-style: italic;
}
.prod-tech-spec-icon{
  font-size: 10px;
  font-weight: 400;
  color: $silver;
}
.product-name-header {
  color: $coldsteel !important;
  font-size: 18px;
  font-weight: 500;
}
.prod-tech-spec-text{
  font-size: 14px;
  font-weight: 500;
  color: $grey;
}
.product-name-details-header{
    color: $elctradarkblue !important;
    font-size: 18px;
    font-weight: 500;
}
.txn-status-icon{
  height: 35px;
  width: 35px;
}

.image-container-prod-power {
  position: relative;
  display: inline-block;
  width: 100% !important;
  height: 100% !important;
}

.dash-product-image-prod-power {
  display: block;
  width: 60px !important;
  height: 60px !important;
  max-height: 100px;
}

.overlay-prod-power {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.image-reject-prod-power {
  // width: 50%;
  height: auto;
}
.transit-button-display-mob {
  display: none !important;
}
.transit-button-display-desk {
  display: block !important;
}
@media screen and (max-width: 576px) {
  .dashboard-sadmin-row-profile-tab-mobile {
    display: block !important;
    // margin-top: 10px;
  }

  .dashboard-sadmin-row-profile-tab {
    display: none !important;
    width: 100% !important;
  }

  .transit-button-display-mob {
    display: block !important;
  }
  .transit-button-display-desk {
    display: none !important;
  }

  .dashboard-sadmin-tab-profile-icon {
    font-size: 20px;
    margin-right: 10px;
  }
  .dashboard-sadmin-profile-tab {
    border: none !important;
  }
  .dashboard-sadmin-profile-nav-tabs {
    letter-spacing: 1px;
    font-size: 14px;
    font-weight: 600;
    margin: 5px 10px !important;
    padding: 10px 25px;
    border: none !important;
  }
  .dashboard-sadmin-profile-nav-tabs.active {
    color: $elctradarkblue !important;
    background: $whitesmoke !important;
    letter-spacing: 1px;
    border: none;
    border-bottom: 2px solid $elctraliteblue !important;
  }
  .dash-margin-mobile{
    margin-top: 10px;
  }
}
