@import "../../../styles/scss/index.scss";

.card-scroller {
  .card-container {
    display: flex;
    justify-content: center;
  }

  .card {
    width: 100%;
    // max-width: 300px;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .card-image-container {
    position: relative;
    width: 100%;

    img {
      width: 100%;
      height: 300px;
      border-radius: 1px;
    }

    .image-navigation-button {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      // background-color: rgba(30, 29, 29, 0.3);
      background-color: $white;
      color: $onyx;
      border: none;
      border-radius: 50%;
      padding: 3px 11px 3px 10px;
      cursor: pointer;
      &:hover {
        background-color: rgba(30, 29, 29, 0.3);
        color: $white;
      }
      &.left {
        left: 1px;
      }

      &.right {
        right: 1px;
      }
    }
  }

  .card-text {
    margin-top: 10px;
    font-size: 16px;
    font-weight: 600;
    color: $onyx;
  }
  .navigation-button {
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      background-color: $onyx;
      border-radius: 50%;
      color: $neonbright;
      border: none;
      cursor: pointer;
      font-size: 20px;
      font-weight: 600;
      padding: 1px 12px 2px 13px;

      &:hover {
        background-color: $neonbright;
        color: $onyx;
      }

      &:disabled {
        background-color: $whisper;
        color: $grey;
        cursor: not-allowed;
      }
    }
  }
}
.input-search-layer {
  background: transparent;
  border: transparent !important;
  margin: 0;
  padding: 0;
}
.text-title {
  color: $grey;
  font-size: 26px;
  font-weight: 700;
}
.arrow-icon {
  font-size: 19px;
  font-weight: 900;
}
.round-small-icon {
  font-size: 12px;
  font-weight: 900;
}
.card-lite-home-height {
  min-height: 600px !important;
}
//Powermart About
.about-title {
  font-size: 20px;
  font-weight: 500;
  color: $onyx;
}
.about-sub-title {
  font-size: 16px;
  font-weight: 500;
  color: $medcarmine;
}

.about-sub-title-support {
  font-size: 16px;
  font-weight: 600;
  color: $onyx;
}

.about-text {
  font-size: 15px;
  font-weight: 500;
  color: $onyx;
}
.support-layout {
  margin-top: 2rem;
}
// image gallery scss

.image-gallery-container {
  width: 100%;
  overflow: hidden;
  position: relative;
  background-color: transparent;
}

.image-gallery {
  display: flex;
  position: relative;
}

.image-gallery img {
  width: 100px;
  height: 100px;
  margin-right: 6rem !important;
}

.human-label {
  background-color: $whitesmoke;
  color: $onyx;
  font-size: 14px;
  font-weight: 900;
  padding: 7px 20px;
}
.product-intro-label {
  background-color: $whitesmoke;
  color: $coldsteel;
  font-size: 16px;
  font-weight: 500;
  padding: 6px 10px;
}
.home-filter-icon{
  display: block;
}
// Image galery SCSS
.key-highlights-cards {
  border: none;
  box-shadow: 0px 10px 17px rgba(244, 244, 249, 0.38);
  border-radius: 8px;
  background-color: rgba(255, 251, 249, 0.7);
}
.key-highlights-cards-disabled {
  border: none;
  box-shadow: 0px 10px 17px rgba(244, 244, 249, 0.38);
  border-radius: 8px;
  background-color: rgba(255, 251, 249, 0.7);
  opacity: .75;
}
.cat-prod-image {
  width: 65px;
  height: 65px;
  border-radius: 50%;
}
// full view start
.margin-top-up {
  margin-top: 2.1rem;
}
.card-filter-power {
  background-color: $greenvogue;
  border: 1px solid $salem;
  box-shadow: 2px 5px 10px rgba(21, 22, 23, 0.1);
  padding: 15px 2px;
  border-radius: 1px;
}
.input-wrapper {
  position: relative;
  display: inline-block;
  width: 100%;
}

.input-wrapper input[type="text"] {
  border: 1px solid $greenvogue !important;
  background: $white !important;
  border-radius: 25px !important;
  height: 45px !important;
  color: $onyx;
  font-size: 16px;
  font-weight: 400;
  &:focus {
    box-shadow: none;
    border-color: $grey !important;
    color: $onyx;
    background-color: $white !important;
  }
  &:hover {
    border: 1px solid $grey !important;
  }
}

.input-wrapper button {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  border: none;
  background-color: transparent;
  color: $orangerough;
  padding: 0 13px;
  cursor: pointer;
  border-radius: 50%;
  &:focus {
    outline: 0;
    box-shadow: none !important;
    background-color: transparent !important;
  }
  &:hover {
    background-color: transparent;
    color: $elctradarkblue !important;
  }
}
.align-fullview-tab {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
.button-open {
  background: $white;
  border:1px solid $elctradarkblue;
  border-radius: 3px;
  color: $elctradarkblue !important;
  font-size: 15px;
  font-weight: 500;
  padding: 5px 15px;
  margin-bottom: 3px !important;
  cursor: pointer;
  &:focus {
    outline: 0;
    box-shadow: none !important;
    color: $elctradarkblue !important;
    background-color: $white !important;
  }
  &:hover {
    background-color: $white;
    color: $elctradarkblue !important;
    border:1px solid $elctradarkblue;
  }
}
.button-open[disabled] {
  background: transparent;
  color: $grey !important;
  border: 1px solid $grey;
}
// full view end
.card-min-ht-fullview {
  min-height: 80vh;
}
.card-min-ht-fullview-header {
  min-height: 45vh;
}

.product-name-header-fullview {
  color: $coldsteel !important;
  font-size: 17px;
  font-weight: 600;
}

@media (max-width: 768px) {
  .card-scroller {
    .card-container {
      display: block;
    }

    .card {
      margin: 0 auto 20px;
    }

    .navigation-button {
      button {
        font-size: 24px;
        background-color: transparent;
        color: $onyx;
        &:hover {
          background-color: transparent;
          color: $onyx;
        }
      }
    }
  }
  .home-filter-icon{
    display: none;
  }
  .cat-prod-image {
    width: 55px;
    height: 55px;
    margin-left: 10px;
    margin-right: 10px;
  }
  .align-fullview-tab {
    display:contents;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .margin-top-dropdwn {
    margin-top: 1rem !important;
  }
}
