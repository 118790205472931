@import "./../../styles/scss/index.scss";

$border-color: #D9E2EC;
$text-color: #102A43;
$text-color-inactive: #829AB1;
$text-color-optional: #9FB3C8;

.stepper-horizontal {
  display: table;
  width: 100%;
  margin: 0 auto !important;
  .step {
    display: table-cell;
    position: relative;
    padding: .45rem;
    z-index: 2;
    width: 25%;

    .step-circle {
      width: 1.5rem;
      height: 1.5rem;
      margin: 0 auto;
      border-radius: 50%;
      text-align: center;
      line-height: 1.4rem;
      font-size: 14px;
      font-weight: 400;
      z-index: 2;
      border: 1px solid $border-color;
    }

    &.done {
      .step-circle {
        background-color: $salem;
        border: 2px solid $onyx;
        color: #ffffff;
        &:before {
          font-family: "FontAwesome";
          font-weight: 100;
        }
      }
      .step-title {
        color: $salem;
        font-size: 12px;
        font-weight: 500;
      }
    }

    &.editing {
      .step-circle {
        background: $white;
        border-color: $salem;
        color: $salem;
      }
      .step-title {
        color: $salem;
        font-size: 12px;
        font-weight: 500;
        text-decoration: underline;
      }
    }

    .step-title {
      margin-top: .1rem;
      font-size: 1rem;
      font-weight: 500;
    }
    .step-title,
    .step-optional {
      text-align: center;
      color: $text-color-inactive;
      font-size: 13px;
      font-weight: 400;
    }
    .step-optional {
      font-size: 0.75rem;
      font-style: italic;
      color: $text-color-optional;
    }

    .step-bar-left,
    .step-bar-right {
      position: absolute;
      top: calc(1.1rem + 2px);
      height: 1px !important;
      background-color: $silver;
      // border: .09rem solid $border-color !important;
      border-width: 1px 0;
    }
    .step-bar-left {
      width: calc(100% - 2rem);
      left: 50%;
      margin-left: 1rem;
      z-index: -1;

    }
    .step-bar-right {
      width: 0;
      left: 50%;
      margin-left: 1rem;
      z-index: -1;
      transition: width 500ms ease-in-out;
    }

    &.done .step-bar-right {
      // background-color: $rust;
      // border-color: $salem;
      border: 1px solid $salem;
      z-index: 3;
      width: calc(100% - 2rem);
    }
  }

  .step.done .step-circle,
  .step.editing .step-circle,
  .step.editing + .step.done .step-circle {
    background-color: $salem;
    border-color: $salem;
    color: #ffffff;
  }
}
.col-md-8 {
  flex: 0 0 100% !important; /* Override the width */
  max-width: 100% !important; /* Ensure it doesn't exceed the parent width */
}
@media screen and (max-width: 576px) {
  .stepper-horizontal {
    display: flex;
    overflow-x: auto; 
    width: 100%;
  }
}