@import "../../../../../styles/scss/index.scss";

.stepper-container {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    font-family: Arial, sans-serif;
  }
  
  .stepper-buttons {
    display: flex;
    flex-direction: column;
    margin-right: 1px;
    background-color: $milkwhite;
    padding: 1px 10px;
    border-radius: 5px;
    border: 1px solid $whisper;
    box-shadow: 2px 5px 10px rgba(21, 22, 23, 0.1);
    // border-right: 1px solid $whisper !important;
  }
  
  .step-power {
    margin-bottom: 15px;
    cursor: pointer;
    transition: background-color 0.3s;
    background-color: transparent !important;
    border: 1px solid $silver !important;
    border-radius: 10px;
    width: 100% !important;
    padding: 8px 1px;
    font-size: 15px !important;
    font-weight: 500 !important;
    color: $onyx !important;
    cursor: pointer;
    &:focus {
        outline: 0;
        box-shadow: none !important;
    }
    &:hover {
        background-color: $onyx !important;
        color: $elctraliteblue !important;
    }
  }
  
  .step-power.active {
    border: transparent !important;
    background-color: $onyx !important;
    color: $elctraliteblue !important;
    border-radius: 10px;
    width: 100% !important;
    padding: 8px 1px;
    font-size: 15px !important;
    font-weight: 500 !important;
    cursor: pointer;
    &:focus {
        outline: 0;
        box-shadow: none !important;
    }
    &:hover {
        background-color: $onyx !important;
        color: $elctraliteblue !important;
    }
  }
  
  .stepper-content {
    flex-grow: 1;
  }
  
  .step-content {
    margin-bottom: 20px;
  }
  
  .navigation-buttons {
    display: flex;
    gap: 10px;
  }
  .text-show-white-po {
    font-size: 16px;
    color: $neonbright;
  }
  .upward-font-ship {
    font-size: 9px;
  }
  @media (max-width: 560px) {
    .stepper-container {
      flex-direction: column;
      overflow-x: auto;
      margin: 0 !important;
      padding: 0 !important;
      align-items: center;
    }
    // .stepper-buttons {
    //     display: flex;
    //     flex-direction: column;
    //     border-right: none !important;
    //   }
      .step-power {
        width: 100%;
        border-radius: 2px;
      }
      .step-power.active {
        width: 100%;
        border-radius: 2px;
      }
  }