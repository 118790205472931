@import "./../../../styles/scss/index.scss";

.avatar-user-profile-preview {
    margin-right: 30px;
    margin-bottom: 10px;
    width: 180px;
    height: 180px;
    padding: 2px;
    border: 1px solid $aliceblue;
  }
.profile-image-btn{
    border: 0 none !important;
}
.profile-image-btn-border{
  border: 1px solid $aliceblue !important;
}