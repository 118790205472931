@import "../../styles/scss/index.scss";

.tooltip-wrapper {
  position: relative;
  display: inline-block;
}

.tooltip {
  position: absolute;
  background-color: $onyx;
  color: #fff;
  text-align: center;
  border-radius: 4px;
  padding: 5px 10px;
  z-index: 1;
  opacity: 0.9;
  white-space: nowrap;
  max-width: 300px;
  word-wrap: break-word;

  &.tooltip-top {
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    margin-bottom: 5px;

    &::after {
      content: '';
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
      border-width: 5px;
      border-style: solid;
      border-color: #333 transparent transparent transparent;
    }
  }

  &.tooltip-right {
    top: 50%;
    left: 100%;
    transform: translateY(-50%);
    margin-left: 5px;

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      right: 100%;
      transform: translateY(-50%);
      border-width: 5px;
      border-style: solid;
      border-color: transparent transparent transparent #333; /* Custom arrow color for right */
    }
  }

  &.tooltip-bottom {
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 5px;

    &::after {
      content: '';
      position: absolute;
      bottom: 100%;
      left: 50%;
      transform: translateX(-50%);
      border-width: 5px;
      border-style: solid;
      border-color: transparent transparent #333 transparent; /* Custom arrow color for bottom */
    }
  }

  &.tooltip-left {
    top: 50%;
    right: 100%;
    transform: translateY(-50%);
    margin-right: 5px;

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 100%;
      transform: translateY(-50%);
      border-width: 5px;
      border-style: solid;
      border-color: transparent #333 transparent transparent; /* Custom arrow color for left */
    }
  }
}

.tooltip-content {
  font-size: 14px;
  font-weight: 500;
}
