@import "../../../../styles/scss/index.scss";
.cart-menu-icon {
  color: $white;
  font-size: 16px;
  font-weight: 900;
}
.acc-margin-bottom{
  margin-top: 18px !important;
}
.cart-text {
  color: $white;
  font-size: 14px !important;
  font-weight: 400 !important;
}
.kart-icon {
  background-color: $white !important;
  color: $onyx;
  border-radius: 20px;
  // height: 20px;
  // width: auto;
  top: -10px !important;
}
.bank-bg {
  background-image: url('../../../../../public/images/bank-bg.png');
  background-repeat: no-repeat;
  min-height: 110vh;
  margin-top: 0 !important;
  background-position: center;
  background-size: cover;
}

.cart-checkout-label {
    color: $elctradarkblue !important;
    background-color: $aliceblue !important;
    font-size: 16px;
    font-weight: 500;
    margin: 5px 4px !important;
    padding: 10px 30px;
    border-radius: 2px;
}

.checkout-amount-total-label {
  background-color: transparent !important;
  // margin: 5px 4px !important;
  padding: 4px 30px;
  border: 1px solid $silver;
  border-radius: 4px;
}
.card-kotak{
    background-image: url('../../../../../public/images/kot-cheque.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    min-height: 20rem;
}
.kot-icon-des{
    height: 60px;
    width: auto;
    padding: 2px 5px;
    margin-left: 5px;
}
.acc-numb-text {
    color: $onyx;
    font-size: 30px;
    font-weight: 900;
}
.acc-name-text {
    color: $onyx;
    font-size: 20px;
    font-weight: 600;
}
.acc-add-text {
    color: $onyx;
    font-size: 16px;
    font-weight: 500;
}
.acc-numb-label {
    color: $grey;
    font-size: 16px;
    font-weight: 900;
}
.txn-info-text {
    color: $rust;
    font-size: 14px;
    font-weight: 400;
    font-style: italic;
  }
.center-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
  }
@media screen and (max-width: 576px) {
  .cart-menu-icon {
    font-size: 28px;
  }
  .cart-margin {
    margin-bottom: 1.1rem;
    margin-right: 10px;
  }
}
