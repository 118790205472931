@import url("https://fonts.googleapis.com/css?family=Poppins");
*,
*::before,
*::after {
  box-sizing: border-box;
}
.bg-pages{
  background-color: $white;
}
.card-body{
  padding: 2px 2px;
}
.popover{
  width: 100% !important;
}
.button-icon-small-pdf {
  color: $rust !important;
}
.button-icon-url {
  color: $rust !important;
  font-size: 12px;
}
.text-header{
  font-size: 22px;
  color: $grey;
  font-weight: 500;
}
.text-channel-partner{
  font-size: 13px;
  color: $dodgerblue;
  font-weight: 800;
}
.product-name-header-link {
  color: $onyx !important;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  &:hover {
    color: $elctradarkblue !important;
  }
}
.alert-loader{
  color: $white;
  font-size: 20px;
  font-weight: 900;
}

.correct-pass-key {
  color: $salem;
  font-size: 14px;
  font-weight: 400;
}
.wrong-pass-key {
  color: $rust;
  font-size: 14px;
  font-weight: 400;
}
.tex-red-italic-lite {
  color: $onyx;
  font-size: 14px;
  font-weight: 700;
  font-style: italic;
  background-color: $whitesmoke;
}
.register-file-link-active{
  color: $salem;
  font-size: 18px;
  font-weight: 700;
  background-color: $whitesmoke;
}
.register-file-link-lite-active{
  color: $salem;
  font-size: 15px;
  font-weight: 500;
  background-color: $whitesmoke;
}
ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.avatar-cards {
  height:80px;
  width: 80px;
}
.image-reject {
  height:40px;
  width: 40px;
}
.label-margin-to-nav{
  padding-top: 75px;
  padding-bottom: 25px;
}
.powermart-main-label {
  color: $grey !important;
  background-color: $whitesmoke !important;
  font-size: 13px;
  font-weight: 500;
  margin: 5px 4px !important;
  padding: 8px 10px;
  border-radius: 2px;
}

.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999;
}
.spinner-img {
  animation: spin 1s linear infinite;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loader-image-size {
  height: 40px;
}
.spin-grow-loader {
  color: $rust;
  height: 6px !important;
  width: 6px !important;
  margin: 0 1px;
}

.spin-grow-loader-white {
  color: $white;
  height: 6px !important;
  width: 6px !important;
  margin: 0 1px;
}

.cursor-pointer {
  cursor: pointer;
}

.powermart-lite-icon {
  color: $grey !important;
  padding: 2px;
}
.powermart-super-lite-icon {
  color: $grey !important;
  font-size: 10px;
}
.powermart-dark-icon {
  color: $onyx !important;
  padding: 2px;
}
.card-lite {
  background-color: $white;
  border: 1px solid $aliceblue;
  box-shadow: 1px 6px 8px rgb(0 55 183 / 8%);
  border-radius: 8px;
}
.card-lite-opaq {
  background-color: $whitesmoke;
  border: 1px solid $aliceblue;
  box-shadow: 1px 6px 8px rgb(0 55 183 / 8%);
  border-radius: 8px;
}

.card-borderless {
  background-color: $white;
  border: 1px solid $whisper;
  box-shadow: 2px 4px 5px rgba(180, 175, 248, 0.3);
  border-radius: 8px;
  // &:hover {
  //   background-color: $whitesmoke;
  // }
}
.form-card-header {
  background: $milkwhite;
  border: none;
  font-size: 16px;
  font-weight: 500;
  color: $onyx;
}
.powermart-user-icon {
  height: 40px;
  width: 40px;
  margin: 3px
}
.rounded-image {
  border-radius: 50%;
  overflow: hidden;
  border: 1px solid $aliceblue;
  padding: 5px;
}
.form-card-footer {
  background: $white;
  border: none;
}
.offcanvas.offcanvas-end{
  width: 60% !important;
  padding-left: 1.2rem;
  padding-right: 5px;
}
.offcanvas-header .btn-close {
  &:focus {
    background-color: transparent;
  }
}

.border-bottom-box{
  border-bottom: 1px solid $milkwhite;
  box-shadow: 1px 1px 1px rgb(0 55 183 / 8%);
}
.text-pre-lite-grey {
  color: $grey;
  font-size: 12px;
  font-weight: 300;
}
.text-lite-grey {
  color: $grey;
  font-size: 14px;
  font-weight: 400;
}
.text-bold-grey {
  color: $onyx;
  font-size: 14px;
  font-weight: 400;
}
.menu-link-notification {
  text-decoration: none;
  display: flex;
  align-items: center;
  padding: 1px 5px;
}

.text-link {
  color: $dodgerblue;
  font-size: 14px;
  font-weight: 600;
  font-style: italic;
  &:hover {
    color: $matrix;
  }
}
.text-link-user {
  color: $grey;
  font-size: 14px;
  font-weight: 600;
  font-style: italic;
  &:hover {
    color: $matrix;
  }
}
.card-toggle-text {
  font-size: 15px;
  font-weight: 500;
  color: $coldsteel;
}
.alert-error {
  font-size: 15px;
  font-weight: 500;
  color: $rust;
}

.alert-success {
  font-size: 15px;
  font-weight: 500;
  color: $onyx;
}

.mandate-star {
  color: $rust;
  margin: 1px;
}

.powermart-input {
  border: 1px solid $silver !important;
  background: $white !important;
  border-radius: 5px !important;
  height: 45px !important;
  color: $onyx;
  font-size: 16px;
  font-weight: 400;
  &:focus {
    box-shadow: none;
    border-color: $elctraliteblue !important;
    color: $onyx;
    background-color: $white !important;
  }
  &:hover {
    border: 1px solid $elctraliteblue !important;
  }
}

.powermart-input[disabled] {
  background: $milkwhite !important;
  border-color: $whitesmoke!important;
  &:hover {
    border-color: $whitesmoke !important;
  }
  &:focus {
    box-shadow: none;
    border-color: $whitesmoke !important;
    color: $onyx;
    background-color: $silver !important;
  }
}
.powermart-input-desc {
  border: 1px solid $silver !important;
  background: $white !important;
  border-radius: 5px !important;
  height: 105px !important;
  color: $onyx;
  font-size: 16px;
  font-weight: 400;
  &:focus {
    box-shadow: none;
    border-color: $elctraliteblue !important;
    color: $onyx;
    background-color: $white !important;
  }
  &:hover {
    border: 1px solid $elctraliteblue !important;
  }
}

.powermart-button-red {
  background-color: $rust !important;
  border: transparent !important;
  border-radius: 3px;
  padding: 5px 12px;
  font-size: 15px !important;
  font-weight: 500 !important;
  color: $white !important;
  cursor: pointer;
  &:focus {
    outline: 0;
    box-shadow: none !important;
  }
  &:hover {
    background-color: $onyx !important;
    color: $elctraliteblue !important;
  }
}

.powermart-button-solid {
  background-color: $coldsteel !important;
  border: transparent !important;
  border-radius: 3px;
  padding: 5px 12px;
  font-size: 15px !important;
  font-weight: 500 !important;
  color: $milkwhite !important;
  cursor: pointer;
  &:focus {
    outline: 0;
    box-shadow: none !important;
  }
  &:hover {
    background-color: $onyx !important;
    color: $elctraliteblue !important;
  }
}

.powermart-button-solid[disabled] {
  background: $grey !important;
  color: $whitesmoke !important;
  border: transparent !important;
}

.powermart-button-fullwidth {
  background-color: $coldsteel !important;
  border: transparent !important;
  border-radius: 3px;
  padding: 10px 15px;
  width: 100%;
  font-size: 15px !important;
  font-weight: 500 !important;
  color: $milkwhite !important;
  cursor: pointer;
  &:focus {
    outline: 0;
    box-shadow: none !important;
  }
  &:hover {
    background-color: $onyx !important;
    color: $elctraliteblue !important;
  }
}

.powermart-button-fullwidth[disabled] {
  background: $grey !important;
  color: $whitesmoke !important;
  border: transparent !important;
}

.powermart-button-fullwidth-red {
  background-color: $rust !important;
  border: transparent !important;
  border-radius: 3px;
  padding: 10px 15px;
  width: 100%;
  font-size: 15px !important;
  font-weight: 500 !important;
  color: $milkwhite !important;
  cursor: pointer;
  &:focus {
    outline: 0;
    box-shadow: none !important;
  }
  &:hover {
    background-color: $onyx !important;
    color: $elctraliteblue !important;
  }
}

.powermart-button-fullwidth-red[disabled] {
  background: $grey !important;
  color: $whitesmoke !important;
  border: transparent !important;
}

.button-round {
  background-color: $white !important;
  border: 1px solid $elctraliteblue !important;
  border-radius: 50%;
  padding: 6px 10px;
  font-size: 14px !important;
  font-weight: 500 !important;
  color: $elctradarkblue !important;
  cursor: pointer;
  &:focus {
    outline: 0;
    box-shadow: none !important;
    background-color: $white !important;
  }
  &:hover {
    border: 1px solid $elctradarkblue !important;
    color: $onyx !important;
  }
}

.powermart-button-link {
  background: transparent;
  border: none;
  color: $dodgerblue !important;
  font-size: 13px;
  font-weight: 500;
  font-style: italic;
  padding: 0;
  margin-bottom: 2px;
  cursor: pointer;
  &:focus {
    outline: 0;
    box-shadow: none !important;
    background-color: transparent !important;
  }
  &:hover {
    background-color: transparent;
    color: $matrix !important;
  }
}
.powermart-button-link[disabled] {
  background: transparent;
  color: $grey !important;
}

.powermart-button-link-red {
  background: transparent;
  border: none;
  color: $medcarmine !important;
  font-size: 15px;
  font-weight: 500;
  font-style: italic;
  padding: 0;
  margin-bottom: 3px !important;
  cursor: pointer;
  &:focus {
    outline: 0;
    box-shadow: none !important;
    background-color: transparent !important;
  }
  &:hover {
    background-color: transparent;
    color: $coldsteel !important;
  }
}
.powermart-button-link-red[disabled] {
  background: transparent;
  color: $grey !important;
}

.button-icon[disabled] {
  background: transparent;
  color: $silver !important;
}

.button-icon {
  background: transparent;
  border: none;
  color: $thunderbird !important;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  &:focus {
    outline: 0;
    box-shadow: none !important;
    background-color: transparent !important;
  }

  &:hover {
    background-color: transparent;
    color: $elctradarkblue !important;
  }
}

.button-icon-text {
  background: transparent;
  border: none;
  color: $grey !important;
  font-size: 12px;
  cursor: pointer;
  &:focus {
    outline: 0;
    box-shadow: none !important;
    background-color: transparent !important;
  }

  &:hover {
    background-color: transparent;
    color: $elctradarkblue !important;
  }
}

.nav-tabs {
  border-bottom: none !important;
}
.form-collapse-card-header {
  background: $whitesmoke;
  border: none;
  font-size: 14px;
  font-weight: 600;
  color: $rust;
}

.tooltip {
  position: absolute;
}
.tooltip-inner {
  background: $white;
  border: 1px solid $whitesmoke;
  color: $onyx;
  font-weight: 500;
  font-size: 12px;
  padding: 5px 10px;
  margin-bottom: 5px;
}
.tooltip.show .arrow::before {
  border-color: $silver transparent $grey transparent;
}

.powermart-check-box {
  color: $elctraliteblue;
  margin-top: 5px;
  outline: 1px solid $elctraliteblue !important;
  border: 1px solid $white !important;
  box-shadow: 0 0 1px $elctraliteblue;
  margin-right: 10px;
  width: 13px;
  height: 13px;
  border-radius: 1px !important;
}

.powermart-check-box:checked {
  background-color: $elctradarkblue !important;
  border: 1px solid $elctradarkblue !important;
  box-shadow: 0 0 1px $elctradarkblue;
}

.powermart-check-box:focus,
.label::after,
.label.powermart-check-box:focus,
.powermart-check-box:checked::after,
.powermart-check-box:not(.disabled):not(.disabled):active:focus {
  color: $elctradarkblue;
  outline: 1px solid $elctradarkblue !important;
  border: 1px solid $white !important;
  box-shadow: 0 0 1px $elctradarkblue;
}

input[type=file]::file-selector-button {
  background-image: linear-gradient(10deg,
      $coldsteel 10%,
      $elctradarkblue 100%);
  border: none !important;
  border-radius: 2px;
  margin: 1px 3px 2px 5px;
  padding: 5px 15px;
  font-size: 14px !important;
  font-weight: 500 !important;
  color: $white !important;
  cursor: pointer;
  &:focus {
    outline: 0 !important;
    box-shadow: none !important;
  }
  &:hover {
    background-image: linear-gradient(100deg,
        $coldsteel 10%,
        $elctraliteblue 80%);
    color: $onyx !important;
  }
}

.upload-box {
  border: 1px dashed #b6bed1;
  background-color: #f0f2f7;
  border-radius: 4px;
  min-height: 50px;
  position: relative;
  overflow: hidden;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #8194aa;
  font-weight: 400;
  font-size: 15px;
}
.upload-box .upload-box-input {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
}

.upload-box-input[disabled] {
  cursor: not-allowed;
}

.align-start {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.align-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.align-end {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
.text-center-aligned {
  text-align: center !important;
}

.align-text-justify {
  text-align: justify !important;
}
// Select Align Changes
// .css-1fdsijx-ValueContainer{
//   overflow: 'hidden';
//   text-overflow: 'ellipsis';
//   white-space: 'nowrap';
//   width: 100% !important;
//   padding: 0;
// }

.notification-text {
  color: $onyx !important;
  font-size: 15px;
  font-weight: 500;
}
.hr-vertical{
  border-left: 1px solid $aliceblue !important;
  min-height: 60vh;
}
.hr-horz{
  color: $silver-lite;
  border-width: 2px;
}

.styled-table {
  width: 100% !important;
  border-collapse: collapse;
  margin-top: 20px;
  border-bottom: 1px solid $whitesmoke;
  border-left: 1px solid $milkwhite;
}

.styled-table th, .styled-table td {
  border: 1px solid $silver-lite;
  padding: 8px;
  text-align: left;
  width: 1200px !important;
}

.styled-table th {
  background-color: #f2f2f2;
  border-bottom: 1px solid $silver-lite;
}

.styled-table tbody tr:nth-child(even) {
  background-color: #f9f9f9;
  border-bottom: 1px solid $silver-lite;
}
// .modal{
//   width: 1700px !important;
// }
.modal-content {
  overflow: hidden;
}

.modal-body {
  max-height: calc(100vh - 200px);
  overflow-y: auto;
  padding: 1px 3px;
}
.dropdown-menu{
  --bs-dropdown-link-active-bg: $salem !important;
}
.loader-container-none {
  // position: fixed;
  // top: 0;
  // left: 0;
  // width: 100%;
  // height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}
// Custom file input Start
/* CustomFileInput.css */
.custom-file-input {
  position: relative;
  display: inline-block;
}

.custom-file-input input[type="file"] {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.custom-file-input .file-label {
  display: inline-block;
  padding: 10px 30px;
  background-color: $greenvogue;
  color: white;
  border-radius: 4px;
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
}

.custom-file-input .file-label:hover {
  background-color: $greenvogue;
}

.custom-file-input .file-name {
  margin-left: 10px;
  vertical-align: middle;
}

// Custom file input end
.my-1 {
  margin: 5px 0;
}

.my-2 {
  margin: 10px 0;
}

.mx-1 {
  margin: 0 5px;
}

.mx-2 {
  margin: 0 10px;
}

.mx-3 {
  margin: 0 1rem;
}

.mx-4 {
  margin: 0 2rem;
}

.mx-5 {
  margin: 0 3rem;
}
.mx-6 {
  margin: 0 4rem;
}

.ml {
  margin-left: 0.5rem;
}

.ml-1 {
  margin-left: 1rem;
}

.ml-2 {
  margin-left: 2rem;
}

.ml-3 {
  margin-left: 3rem;
}

.ml-4 {
  margin-left: 4rem;
}
.pl {
  padding-left: 0.5rem;
}
.pl-1 {
  padding-left: 1rem;
}

.mp {
  padding: 0.3rem;
}

.mp-1 {
  padding: 1rem;
}

.mp-2 {
  padding: 2rem;
}

.mptb {
  padding: 0.3rem 0;
}

.mptb-1 {
  padding: 1rem 0;
}

.mptb-2 {
  padding: 2rem 0 !important;
}

.ptop-1 {
  padding-top: 1rem;
}

.ptop-2 {
  padding-top: 1.5rem;
}

.ptop-3 {
  padding-top: 2rem;
}

.pb-1 {
  padding-bottom: 10px;
}
.mr {
  margin-right: .5rem;
}
.mr-1 {
  margin-right: 1rem;
}

.mr-2 {
  margin-right: 2rem;
}
.mr-3 {
  margin-right: 3rem;
}
.mr-4 {
  margin-right: 4rem;
}

.mb {
  margin-bottom: 5px;
}

.mb-1 {
  margin-bottom: 10px;
}

.mt {
  margin-top: 5px;
}

.mt-1 {
  margin-top: 10px !important;
}

.mt-2 {
  margin-top: 20px !important;
}

.mlr {
  padding: 0 .5rem;
}

.mlr-1 {
  padding: 0 1rem;
}

.mlr-2 {
  padding: 0 2rem;
}

.margin-zero {
  margin: 0 !important;
}

.padding-zero {
  padding: 0 !important;
}

@media screen and (max-width: 576px) {
.offcanvas.offcanvas-end{
  width: 100% !important;
}
.mobile-marin-top{
  margin-top: 1rem;
}
.cart-text-display {
  display: none;
}
}