body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-image: radial-gradient(farthest-corner at 40px 40px, #fffafa 20%, #ffffff 100%,#ffdbdb 100%);
  /* background-image: url('./../public/images/powermart-bg.png') !important; */
  background-repeat:no-repeat;
  background-size: 100% 100%;
  /* background-image: radial-gradient(farthest-corner at 40px 40px,
        #000000 20%, #070000 100%,#000000 100%);
  background-position: center; */
  /* background-size: cover; */
  min-height: 110vh;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  /* linear-gradient(45deg, rgba(3, 1, 0, 0.90) 1%,  #000b10 80%) !important; */
  /* background-color: rgb(0, 59, 72) !important; */
}
html {
  /* scroll-behavior: smooth; */
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
  overflow: auto;
  /* background-color: '#fffbfb' !important; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}