@import "../../../styles/scss/index.scss";

.navbar-container {
  background-color: $white;
  box-shadow: 2px 10px 14px rgba(3, 102, 202, 0.07);
  position: fixed !important;
  z-index: 100;
  width: 100% !important;
  border-bottom-left-radius: 40px;
  border-bottom-right-radius: 40px;
}
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  // padding: 1rem 0;
}
.navbar-left {
  display: flex;
  align-items: center;
}
.nav-logo {
  text-decoration: none;
  margin-bottom: 0.11rem;
}
.logo-bg {
  height: 40px;
  width: auto;
}
.nav-menu {
  display: flex;
  list-style: none;
  text-align: left;
  padding-top: -10px !important;
  margin-right: 5px;
}
.nav-menu.active {
  background: $white;
  left: 0;
  opacity: 1;
  transition: all 0.5s ease;
  z-index: 1;
  padding-right: 30px !important;
  padding-bottom: 30px;
  box-shadow: 2px 5px 10px rgba(22, 24, 26, 0.07);
}
.nav-icon {
  display: none;
}
.nav-icons-lite {
  font-size: 14px !important;
  color: $grey;
}
.nav-icons-white {
  font-size: 12px !important;
}
.nav-text {
  font-size: 15px !important;
  color: $onyx;
  font-weight: 600;
}
.nav-text-small {
  font-size: 13px !important;
  color: $onyx;
  font-weight: 600;
}
.pipe-nav-lite {
  color: $silver;
  font-size: 15px;
  font-weight: 200;
}
.display-desk {
  margin-right: 1rem;
}
.mobile-view-nav {
  display: none;
}
.button-open-login {
  background: $white;
  border: 1px solid $thunderbird;
  border-radius: 5px;
  color: $elctradarkblue !important;
  font-size: 15px;
  font-weight: 500;
  padding: 4px 10px;
  margin-bottom: 3px !important;
  cursor: pointer;
  &:focus {
    outline: 0;
    box-shadow: none !important;
    color: $elctradarkblue !important;
    background-color: $white !important;
  }
  &:hover {
    background-color: $white;
    color: $elctradarkblue !important;
    border: 1px solid $elctradarkblue;
  }
}
@media screen and (max-width: 480px) {
  .mobile-view-nav {
    display: inline;
  }
  .display-desk {
    display: none !important;
  }
  .nav-menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    position: absolute;
    width: 100%;
    top: 75px;
    // margin-top: 100px;
    left: -110%;
    opacity: 1;
    transition: all 1.1s ease;
  }
  .nav-menu.active {
    background: $white;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
    padding-right: 30px !important;
    padding-bottom: 30px;
    box-shadow: 2px 5px 10px rgba(22, 24, 26, 0.07);
  }
  .navbar-left {
    flex-direction: column;
    align-items: flex-start;
  }
  .navbar-right {
    display: none;
  }
  .nav-icon {
    display: block;
    position: absolute;
    top: 20px;
    right: 20px;
  }
  .nav-close-btn {
    font-size: 32px !important;
    margin-right: 5px;
    color: $onyx;
  }
  .nav-open-btn {
    font-size: 32px !important;
    margin-right: 5px;
    color: $onyx;
  }
}
