@import "../../../../styles/scss/index.scss";

.user-sadmin-row-profile-tab-mobile {
  display: none;
}

.user-sadmin-row-profile-tab {
  display: block;
}

.user-sadmin-column-profile-tab-left {
  float: left;
}
.user-sadmin-column-profile-tab-right {
  float: right;
}
.user-sadmin-profile-nav-tabs {
  color: $elctradarkblue !important;
  background: $whitesmoke !important;
  letter-spacing: 1px;
  font-size: 14px;
  font-weight: 600;
  margin: 1px 10px !important;
  padding: 5px 15px;
}

.user-sadmin-profile-nav-tabs.active {
  color: $elctraliteblue !important;
  background-color: $coldsteel !important;
  letter-spacing: 1px;
  border: none;
  border-bottom: 2px solid $elctraliteblue !important;
}

.super-user-name {
  color: $rust !important;
  font-size: 16px;
  font-weight: 600;
}
.user-type-bg {
  color: $white !important;
  background-color: $rust;
  font-size: 15px;
  font-weight: 600;
  padding: 0 3px;
}

.box-user-type {
  color: $onyx !important;
  font-size: 12px;
  font-weight: 600;
  background-color: $whitesmoke;
  padding: 1px 5px;
  margin: 5px 2px;
}

.user-sadmin-tab-profile-icon {
  font-size: 17px;
  margin-right: 10px;
}
.user-sadmin-card-body-atbtme {
  min-height: 150px !important;
}

.seller-corp-logo{
  height: 45px;
  width: 45px;
}

@media screen and (max-width: 576px) {
  .user-sadmin-row-profile-tab-mobile {
    display: block;
    // margin-top: 10px;
  }

  .user-sadmin-row-profile-tab {
    display: none;
  }

  .user-sadmin-tab-profile-icon {
    font-size: 20px;
    margin-right: 10px;
  }
  .user-sadmin-profile-tab {
    border: none !important;
  }
  .user-sadmin-profile-nav-tabs {
    letter-spacing: 1px;
    font-size: 14px;
    font-weight: 600;
    margin: 5px 10px !important;
    padding: 10px 25px;
    border: none !important;
  }
  .user-sadmin-profile-nav-tabs.active {
    color: $elctradarkblue !important;
    background: $whitesmoke !important;
    letter-spacing: 1px;
    border: none;
    border-bottom: 2px solid $elctraliteblue !important;
  }
}
