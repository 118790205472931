@import "../../../../styles/scss/index.scss";

.metadata-sadmin-row-profile-tab-mobile {
  display: none !important;
}

.metadata-sadmin-row-profile-tab {
  display: block !important;
}

.metadata-sadmin-column-profile-tab-left {
  float: left;
}
.metadata-sadmin-column-profile-tab-right {
  float: right;
}
.metadata-sadmin-profile-nav-tabs {
  color: $elctradarkblue !important;
  background: $whitesmoke !important;
  letter-spacing: 1px;
  font-size: 14px;
  font-weight: 600;
  margin: 1px 10px !important;
  padding: 5px 15px;
}

.metadata-sadmin-profile-nav-tabs.active {
  color: $elctraliteblue !important;
  background-color: $coldsteel !important;
  letter-spacing: 1px;
  border: none;
  border-bottom: 2px solid $elctraliteblue !important;
}

.super-metadata-name {
  color: $rust !important;
  font-size: 16px;
  font-weight: 600;
}

.box-metadata-type {
  color: $onyx !important;
  font-size: 12px;
  font-weight: 600;
  background-color: $whitesmoke;
  padding: 1px 5px;
  margin: 5px 2px;
}

.metadata-sadmin-tab-profile-icon {
  font-size: 17px;
  margin-right: 10px;
}
.metadata-sadmin-card-body-atbtme {
  min-height: 150px !important;
}
.product-preview {
  margin-right: 30px;
  margin-bottom: 10px;
  // width: 180px;
  // height: 180px;
  padding: 10px;
  border: 1px solid $milkwhite;
}
.product-image-btn{
  border: 0 none !important;
}
.product-image-btn-border{
  border: 1px solid $elctraliteblue !important;
}
input[type=file].product-img {
  background: none !important;
  color: $onyx;
  font-size: 13px;
  font-weight: 600;
  &:focus {
    outline: 0 !important;
    box-shadow: none !important;
  }
}
.meta-card{
  background-color: $white;
  border: 1px solid $whitesmoke;
  box-shadow: 1px 6px 8px rgb(0 55 183 / 8%);
  border-radius: 8px;
}
.meta-card-header{
  background: $milkwhite;
  border: none;
  font-size: 16px;
  font-weight: 400;
  color: $onyx;
}
.product-image {
  width: 100%;
  height: 160px;
  padding: 3px;
  border-radius: 10px !important;
}
.desc-product-image {
  width: 100%;
  height: 300px;
  padding: 3px;
}
.border-image{
  border: 1px solid $aliceblue;
  padding: 10px;
  // margin: 3px;
}
.meta-price-main{
  font-size: 25px;
  font-weight: 600;
  color: $onyx;
}
.meta-price-icon{
  font-size: 18px;
  font-weight: 400;
  color: $grey;
  padding: 5px 0;
}
.meta-price-lite{
  font-size: 14px;
  font-weight: 400;
  color: $grey;
}
.meta-waranty-lite{
  font-size: 15px;
  font-weight: 400;
  color: $grey;
  font-style: italic;
}
.prod-tech-spec-icon{
  font-size: 10px;
  font-weight: 400;
  color: $silver;
}
.product-name-header {
  color: $coldsteel !important;
  font-size: 18px;
  font-weight: 500;
}
.prod-tech-spec-text{
  font-size: 14px;
  font-weight: 500;
  color: $grey;
}
.product-name-details-header{
    color: $elctradarkblue !important;
    font-size: 18px;
    font-weight: 500;
}

@media screen and (max-width: 576px) {
  .metadata-sadmin-row-profile-tab-mobile {
    display: block !important;
    // margin-top: 10px;
  }

  .metadata-sadmin-row-profile-tab {
    display: none !important;
  }

  .metadata-sadmin-tab-profile-icon {
    font-size: 20px;
    margin-right: 10px;
  }
  .metadata-sadmin-profile-tab {
    border: none !important;
  }
  .metadata-sadmin-profile-nav-tabs {
    letter-spacing: 1px;
    font-size: 14px;
    font-weight: 600;
    margin: 5px 10px !important;
    padding: 10px 25px;
    border: none !important;
  }
  .metadata-sadmin-profile-nav-tabs.active {
    color: $elctradarkblue !important;
    background: $whitesmoke !important;
    letter-spacing: 1px;
    border: none;
    border-bottom: 2px solid $elctraliteblue !important;
  }
  .meta-margin-mobile{
    margin-top: 10px;
  }
}
