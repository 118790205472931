@import "../../../styles/scss/index.scss";

.navbar-sadmin {
  background: $coldsteel;
  position: fixed !important;
  top: 0;
  margin: 0 !important;
  width: 100%;
  z-index: 1030;
  // box-shadow: 0 0 1px $grey;
}

.sadmin-user-header-grey-text {
  font-size: 14px !important;
  font-weight: 500;
  color: $grey;
}
.sadmin-user-name-text {
  font-size: 14px !important;
  font-weight: 600;
  color: $onyx;
}
.sadmin-grey-icon {
  font-size: 16px !important;
  font-weight: 700;
  color: $grey;
}
.user-info-text {
  font-size: 12px;
  font-weight: 600;
  color: $white;
}
.navbar-sadmin-icon {
  font-size: 2rem !important;
  color: $elctraliteblue;
  padding: 15px 10px !important;
  margin-top: 1px;
}
.navbar-sadmin-close-btn{
  font-size: 2.2rem !important;
}
.navbar-sadmin-open-btn {
  transition: opacity 0.3s ease;
}
.fade-in {
  opacity: 1;
  transition: opacity 0.3s ease;
}

.fade-out {
  opacity: 0;
  transition: opacity 0.3s ease;
}

.pro-sidebar-sadmin {
  background-color: $whitesmoke;
  box-shadow: 5px 1px 10px rgba(22, 24, 26, 0.07) !important;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  transition: 350ms;
  z-index: 1;
  margin-top: 75px;
}
a{
    text-decoration: none;
    color: inherit;
}

.pro-sidebar-sadmin-hide {
  display: none;
}

.pro-sidebar-sadmin-bg {
  padding-top: 10px;
  padding-bottom: 10px;
  box-shadow: 5px 1px 10px rgba(22, 24, 26, 0.07) !important;
}

.menu-container {
  background-color: $whitesmoke;
}

.menu-item-sadm {
  padding: 10px 20px;
  margin: 10px 8px;
  transition: background-color 0.3s ease;
  list-style-type: none;
  text-decoration: none !important;
  color: $onyx;
  font-weight: 600;
  &:focus {
    outline: 0;
    box-shadow: none !important;
  }
  &:hover {
    color: $grey !important;
  }
}

.menu-item-sadm.active {
  background-color: $coldsteel;
  border-radius: 5px;
  text-decoration: none !important;
  color: $white;
  font-weight: 600;
  transition: color 0.3s ease;
  &:hover {
    color: $whitesmoke !important;
  }
}

.menu-icon-sadm {
  font-size: 14px;
  border: 1px solid rgba(22, 24, 26, 0.07);
  border-radius: 4px;
  padding: 5px;
  margin-right: 10px;
  color: $grey;
  background-color: $whitesmoke;
}
.bg-icon-nav-dash{
  margin: 20px 1px;
  border-radius: 1px;
}
.vert-line-dash{
  font-size: 35px;
  font-weight: 200;
  color: $grey;
  margin: 10px 20px;
}
.menu-image-avatar{
  margin-right: 15px;
}
.icon-powermart-logo{
  height: 40px;
  width: auto;
}
@media only screen and (max-width: 576px) {
  .menu-item-sadm.active {
    padding: 20px 20px;
    margin: 0 2px;
  }
  .pro-sidebar-sadm-bg {
    padding: 10px 20px;
  }
  .pro-sidebar-sadmin {
    margin-top: 70px;
  }
  .menu-image-avatar{
    margin-right: 1px;
  }
  .icon-powermart-logo{
    height: 35px;
    width: auto;
    margin-top: 5px;
  }
}
