@import "../../../styles/scss/index.scss";

.navbar-puser {
  background: $medcarmine;
  position: fixed !important;
  top: 0;
  margin: 0 !important;
  width: 100%;
  z-index: 1030;
  // box-shadow: 0 0 1px $grey;
}
.discom-user-button{
  background-color: $thunderbird !important;
  border: 1px solid $thunderbird !important;
  border-radius: 5px;
  padding: 6px 10px;
  // width: 100%;
  font-size: 14px !important;
  font-weight: 500 !important;
  color: $white !important;
  cursor: pointer;
  &:focus {
    outline: 0;
    box-shadow: none !important;
    border: 1px solid $cofeebean !important;
    background-color: $cofeebean !important;
  }
  &:hover {
    background-color: $cofeebean !important;
    color: $elctraliteblue !important;
    border: 1px solid $cofeebean !important;
  }
}

.puser-user-header-grey-text {
  font-size: 14px !important;
  font-weight: 500;
  color: $grey;
}
.puser-user-name-text {
  font-size: 14px !important;
  font-weight: 600;
  color: $onyx;
}
.puser-grey-icon {
  font-size: 16px !important;
  font-weight: 700;
  color: $grey;
}
.user-info-text {
  font-size: 12px;
  font-weight: 600;
  color: $white;
}
.navbar-puser-icon {
  font-size: 2rem !important;
  color: $elctraliteblue;
  padding: 15px 10px !important;
  margin-top: 1px;
}
.navbar-puser-close-btn{
  font-size: 2.2rem !important;
}
.navbar-puser-open-btn {
  transition: opacity 0.3s ease;
}
.fade-in {
  opacity: 1;
  transition: opacity 0.3s ease;
}

.fade-out {
  opacity: 0;
  transition: opacity 0.3s ease;
}

.pro-sidebar-puser {
  background-color: $whitesmoke;
  box-shadow: 5px 1px 10px rgba(22, 24, 26, 0.07) !important;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  transition: 350ms;
  z-index: 1;
  margin-top: 75px;
}
a{
    text-decoration: none;
    color: inherit;
}

.pro-sidebar-puser-hide {
  display: none;
}

.pro-sidebar-puser-bg {
  padding-top: 10px;
  padding-bottom: 10px;
  box-shadow: 5px 1px 10px rgba(22, 24, 26, 0.07) !important;
}

.menu-container {
  background-color: $whitesmoke;
}

.menu-item-sadm {
  padding: 10px 20px;
  margin: 10px 8px;
  transition: background-color 0.3s ease;
  list-style-type: none;
  text-decoration: none !important;
  color: $onyx;
  font-weight: 600;
  &:focus {
    outline: 0;
    box-shadow: none !important;
  }
  &:hover {
    color: $grey !important;
  }
}

.menu-item-sadm.active {
  background-color: $coldsteel;
  border-radius: 5px;
  text-decoration: none !important;
  color: $white;
  font-weight: 600;
  transition: color 0.3s ease;
  &:hover {
    color: $whitesmoke !important;
  }
}

.menu-icon-sadm {
  font-size: 14px;
  border: 1px solid rgba(22, 24, 26, 0.07);
  border-radius: 4px;
  padding: 5px;
  margin-right: 10px;
  color: $grey;
  background-color: $whitesmoke;
}
.bg-icon-nav-dash{
  margin: 5px 1px;
  border-radius: 1px;
}
.vert-line-dash{
  font-size: 35px;
  font-weight: 200;
  color: $grey;
  margin: 10px 20px;
}
.menu-image-avatar{
  margin-right: 15px;
}
.icon-powermart-logo{
  height: 43px;
  width: auto;
}
.cart-margin{
  margin-top: 1px !important;
  margin-right: 1.5rem;
}
.text-lite-white{
  color: $white;
  font-size: 13px;
  font-weight: 600;
}
.cart-menu-icon-mobile {
  font-size: 22px;
  color: $grey;
}
.cart-text-mobile {
  color: $onyx;
  font-size: 14px !important;
  font-weight: 400 !important;
}
footer {
  display: none;
}
.footer-visible {
  display: none !important;
}
.price-range-visible {
  display: block !important;
}
@media only screen and (max-width: 576px) {
  .menu-item-sadm.active {
    padding: 20px 20px;
    margin: 0 2px;
  }
  .pro-sidebar-sadm-bg {
    padding: 10px 20px;
  }
  .pro-sidebar-puser {
    margin-top: 70px;
  }
  .menu-image-avatar{
    margin-right: 1px;
  }
  .icon-powermart-logo{
    height: 35px;
    width: auto;
    margin-top: 5px;
  }
  footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    background-color: $white !important;
    margin-top: 40px;
    box-shadow: 1px 1px 6px 1px rgba(194, 194, 194, 0.9);
    padding: 4px 0 !important;
    display: none;
  }
  .footer-visible {
    display: block !important;
  }
  .icon-mobile-view{
    display: none !important;
  }
  .price-range-visible {
    display: none !important;
  }
}
