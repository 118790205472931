@import "../../styles/scss/index.scss";

.footer-wds {
  clear: both;
  position: relative;
  bottom: 0;
  padding: 10px;
  background: $whitesmoke !important;
}

.text-footer-header {
  color: $grey;
  font-size: 20px;
  font-weight: 500;
}
.text-footer-know-campus {
  color: $grey;
  font-size: 28px;
  font-weight: 700;
}
.text-footer-desc {
  color: $grey;
  font-size: 14px;
  font-weight: 400;
}
.btn-footer-icon {
  border: 1px solid $silver;
  border-radius: 5px;
  padding: 3px 5px;
  margin: 2px;
  min-width: 40px !important;
  cursor: pointer;
  &:focus {
    outline: 0;
    box-shadow: none !important;
  }
  &:hover {
    border: 1px solid $pancho;
  }
}
.footer-wds-icon {
  color: $grey;
}
.copyright {
  color: $grey;
  font-weight: 400;
}
.text-copyright-mail {
  color: $onyx;
  font-weight: 600;
  &:hover {
    color: $thunderbird;
  }
}
.text-footer-abou-count {
  font-size: 35px;
  color: $onyx;
  font-weight: 800;
}
.text-footer-abou-title {
  font-size: 25px;
  color: $white;
  font-weight: 700;
}
